<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { locale } = useI18n();

// PROPS
const { auction, assetId } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
    assetId: { type: String, default: undefined },
});

// COMPOSABLES
const { isBundleMono } = useAuctionType(() => auction);

// DATA
const asset = computed<AuctionAssetBusEntity>(() => (assetId ? auction.assets.find(_a => _a.id === assetId)! : auction.mainAsset));
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(auction));
const hasDetails = computed<boolean>(() =>
    isBundleMono.value
        ? auction!.assets.some(_a => _a.damageNotes[locale.value] || _a.images.damage.length)
        : !!asset.value.damageNotes[locale.value] || !!asset.value.images.damage.length
);
</script>

<template>
    <section v-if="hasDetails" class="flex flex-col gap-6">
        <h2 class="text-lg font-semibold md:text-3xl">{{ tt('adp.detailsTitle', isBundleMono ? 2 : 1) }}</h2>

        <template v-if="isBundleMono">
            <template v-for="(_asset, index) in sortedAssets" :key="_asset.id">
                <BaseAccordion
                    v-if="!!_asset.damageNotes[locale] || !!_asset.images.damage.length"
                    :ui="{ default: { class: 'font-normal text-base' } }"
                    :items="[{ label: tt('asset.vehicleIndex', { index: index + 1 }), defaultOpen: index === 0 }]"
                >
                    <template #item>
                        <AdpDetailsItem :asset="_asset" :index="index + 1" />
                    </template>
                </BaseAccordion>
            </template>
        </template>

        <AdpDetailsItem v-else :asset="asset" />
    </section>
</template>
