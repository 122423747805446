<script setup lang="ts">
import lodashIsNumber from 'lodash-es/isNumber';
import type { PropType } from 'vue';

import { type AuctionItemEntity } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tn } = useTypedI18n();
const { isLgOrGreater } = useResponsive();
const { title, titleColor, titleAnnotation, titleAnnotationColor, isFeeNoticeHidden, priceColor, isCountdownVisible } = useAuctionStatusAdapter(
    toRef(() => auction)
);
</script>

<template>
    <!-- Desktop -->
    <div v-if="isLgOrGreater" class="flex flex-col gap-4">
        <p class="flex gap-3 text-sm font-extrabold uppercase tracking-widest lg:max-xl:flex-col">
            <span :class="titleColor">{{ title }}</span>
            <span v-if="titleAnnotation" :class="titleAnnotationColor">{{ titleAnnotation }}</span>
        </p>

        <template v-if="lodashIsNumber(auction.currentPrice)">
            <p class="text-5xl font-semibold" :class="priceColor">{{ tn(auction.currentPrice, 'currency') }}</p>
            <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" />
        </template>
        <p v-else class="text-5xl font-semibold uppercase" :class="priceColor">*******</p>

        <AdpCountdown v-if="isCountdownVisible" class="text-base" :auction="auction" />
    </div>

    <!-- Mobile/Tablet -->
    <div v-else class="flex flex-col gap-3">
        <p class="flex gap-3 text-xs font-extrabold uppercase tracking-widest">
            <span :class="titleColor">{{ title }}</span>
            <span v-if="titleAnnotation" :class="titleAnnotationColor">{{ titleAnnotation }}</span>
        </p>

        <div class="flex flex-col gap-0">
            <div class="flex items-center justify-between">
                <div>
                    <template v-if="lodashIsNumber(auction.currentPrice)">
                        <p class="text-2xl font-semibold" :class="priceColor">{{ tn(auction.currentPrice, 'currency') }}<sup>*</sup></p>
                        <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" class="hidden text-xs sm:block" />
                    </template>
                    <p v-else class="text-2xl font-semibold uppercase" :class="priceColor">*******</p>
                </div>

                <AdpCountdown v-if="isCountdownVisible" class="flex flex-col items-center text-sm" :auction="auction" />
            </div>
            <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" class="text-xs sm:hidden" />
        </div>
    </div>
</template>
