<script setup lang="ts">
import { AssetType, type AuctionAssetBusEntity } from '~/apiClient';
import type { Breadcrumb } from '~/types/breadcrumb.type';

// PROPS
const { asset } = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();

// DATA
const breadCrumbs = computed<Breadcrumb[] | undefined>(
    () =>
        asset && [
            {
                label: tt('homepage.link'),
                to: 'index',
            },
            {
                label: tt(`auctions.page.asset.${AssetType.Bus}`),
                to: formatAlpRoute({ assetType: AssetType.Bus }),
            },
            {
                label: tt(`asset.class.${asset!.class[0]}`),
                to: formatAlpRoute({ assetType: AssetType.Bus, assetClass: asset!.class[0] }),
            },
            {
                label: asset.brand.brandValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: asset!.class[0],
                    brandUrlKey: asset!.brand.urlKey,
                }),
            },
            {
                label: asset!.model.modelValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: asset.class[0],
                    brandUrlKey: asset.brand.urlKey,
                    modelUrlKey: asset.model.urlKey,
                }),
            },
        ]
);
</script>

<template>
    <BaseBreadcrumbs v-if="breadCrumbs" class="text-neutral-500" :items="breadCrumbs" />
</template>
