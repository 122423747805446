<script setup lang="ts">
import { type AuctionItemEntity } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, td, formatUnits } = useTypedI18n();
const { isMonoAsset, isBundleMono } = useAuctionType(() => auction);

// COMPUTED
const firstRegistration = computed(() => (auction.mainAsset.firstRegistration ? Date.parse(auction.mainAsset.firstRegistration) : undefined));
const showKeyAttributes = computed<boolean>(() => isMonoAsset.value || isBundleMono.value);
</script>

<template>
    <div class="flex flex-wrap gap-3">
        <AuctionBadges :auction="auction" />

        <template v-if="showKeyAttributes">
            <UBadge v-if="firstRegistration" color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
                <UIcon class="h-5 w-5" name="i-mdi-bus" />
                {{ tt('asset.firstRegistration') }}: {{ td(firstRegistration, 'mmyyyy') }}
            </UBadge>
            <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
                <UIcon class="h-5 w-5" name="i-mdi-gauge" />
                {{ formatUnits.km(auction.mainAsset.km) }}
            </UBadge>
            <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
                <UIcon class="h-5 w-5" name="i-mdi-engine" />
                {{ tt(`asset.euroNorm.${auction.mainAsset.euroNorm}`) }}
            </UBadge>
        </template>
    </div>
</template>
