<script setup lang="ts">
import type { ButtonSize } from '#ui/types';
import { Swiper } from 'swiper';
import type { PropType } from 'vue';

// PROPS
const { swiperElement, activeIndex, size, rewind } = defineProps({
    swiperElement: { type: Object as PropType<(HTMLElement & { swiper: Swiper }) | null>, required: false, default: null },
    activeIndex: { type: Number, default: 0 },
    size: { type: String as PropType<ButtonSize>, default: 'sm' },
    rewind: { type: Boolean, default: false },
});

// DATA
const swiper = computed<Swiper | undefined>(() => swiperElement?.swiper);
const prevDisabled = computed<boolean>(() => !rewind && activeIndex === 0);
const nextDisabled = computed<boolean>(
    () => !rewind && !!swiper.value && activeIndex === swiper.value.slides.length - swiper.value.slidesPerViewDynamic()
);
const topOffset = computed<string>(
    () =>
        ({
            '2xs': 'top-[calc(50%-0.75rem)]',
            xs: 'top-[calc(50%-0.875rem)]',
            sm: 'top-[calc(50%-1rem)]',
            md: 'top-[calc(50%-1.125rem)]',
            xl: 'top-[calc(50%-1.5rem)]',
            lg: 'top-[calc(50%-1.675rem)]',
        })[size]
);

// HANDLERS
const slidePrev = (): void => {
    swiper.value?.slidePrev();
};

const slideNext = (): void => {
    swiper.value?.slideNext();
};
</script>

<template>
    <template v-if="$slots.default">
        <div class="relative">
            <UButton
                class="absolute left-0 z-10"
                :class="topOffset"
                color="fl-dark-blue"
                icon="i-heroicons-arrow-left-solid"
                :size="size"
                :disabled="prevDisabled"
                @click.prevent="slidePrev()"
            />
            <UButton
                class="absolute right-0 z-10"
                :class="topOffset"
                color="fl-dark-blue"
                icon="i-heroicons-arrow-right-solid"
                :size="size"
                :disabled="nextDisabled"
                @click.prevent="slideNext()"
            />
            <slot class="relative" />
        </div>
    </template>
    <template v-else>
        <div class="absolute">
            <UButton
                class="absolute left-0 z-10"
                color="fl-dark-blue"
                icon="i-heroicons-arrow-left-solid"
                :size="size"
                :disabled="prevDisabled"
                @click.prevent="slidePrev()"
            />
            <UButton
                class="absolute right-0 z-10"
                color="fl-dark-blue"
                icon="i-heroicons-arrow-right-solid"
                :size="size"
                :disabled="nextDisabled"
                @click.prevent="slideNext()"
            />
        </div>
    </template>
</template>
