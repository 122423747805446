<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity, AuctionItemEntity } from '~/apiClient';

// PROPS
const { auction, assetId } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
    assetId: { type: String, required: true },
});

// DATA
const asset = computed<AuctionAssetBusEntity | undefined>(() => auction.assets.find(_a => _a.id === assetId));
</script>

<template>
    <div v-if="asset" class="border-primary-300 flex flex-col gap-6 rounded-2xl border bg-blue-50 p-6">
        <AdpMultiGallery :asset="asset" />

        <i18n-t scope="global" tag="h2" keypath="auction.multi.detailTitle" class="text-2xl font-bold leading-8">
            <template #name>
                <span class="text-primary">{{ asset.brand.brandValue }} {{ asset.model.modelValue }}</span>
            </template>
        </i18n-t>

        <AdpSpecs :auction="auction" :asset-id="asset.id" />

        <AdpDetails :auction="auction" :asset-id="asset.id" />
    </div>
</template>
