<script setup lang="ts">
import lodashChunk from 'lodash-es/chunk';

import type { AuctionAssetBusEntity, AuctionItemEntity } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { isXlOrGreater, isTabletOrDesktop } = useResponsive();

// DATA
const activeRow = ref<number | null>(null);
const activeCol = ref<number | null>(null);
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(auction));
const itemsPerRow = computed<number>(() => {
    if (isXlOrGreater.value) return 3;
    if (isTabletOrDesktop.value) return 2;

    return 1;
});
const rows = computed<AuctionAssetBusEntity[][]>(() => lodashChunk(sortedAssets.value, itemsPerRow.value));

// HANDLERS
const setActiveItem = (row: number, col: number): void => {
    activeRow.value = row;
    activeCol.value = col;

    nextTick(() => {
        const detailElement = document.getElementById('asset-detail');
        detailElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
};
</script>

<template>
    <div class="flex flex-col gap-8 xl:gap-6">
        <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="flex flex-col gap-8 xl:gap-6">
            <div class="grid gap-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-6">
                <AdpMultiCard
                    v-for="(asset, colIndex) in row"
                    :key="asset.id"
                    class="cursor-pointer"
                    :class="{ 'shadow-primary shadow-[0_0_0_3px]': activeRow === rowIndex && activeCol === colIndex }"
                    :asset="asset"
                    @click="setActiveItem(rowIndex, colIndex)"
                />
            </div>
            <AdpMultiDetail
                v-if="activeCol !== null && activeRow === rowIndex"
                id="asset-detail"
                class="scroll-mt-[calc(var(--header-height)+1rem)]"
                :auction="auction"
                :asset-id="row[activeCol].id"
            />
        </div>
    </div>
</template>
