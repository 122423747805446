import type { ComputedRef } from 'vue';

import { type AuctionItemEntity, type AuctionListingItemEntity, AuctionType } from '~/apiClient';

type UseAuctionTypeReturn = {
    isMonoAsset: ComputedRef<boolean>;
    isBundle: ComputedRef<boolean>;
    isBundleMono: ComputedRef<boolean>;
    isBundleMulti: ComputedRef<boolean>;
};

export default function useAuctionType(auction: MaybeRefOrGetter<AuctionItemEntity | AuctionListingItemEntity | undefined>): UseAuctionTypeReturn {
    const checkAuctionType = (typeList: AuctionType[]): boolean => {
        const _auction = toValue(auction);

        return !!_auction && typeList.includes(_auction.type);
    };

    const isMonoAsset = computed<boolean>(() => checkAuctionType([AuctionType.Mono, AuctionType.MonoToScrap]));
    const isBundleMono = computed<boolean>(() => checkAuctionType([AuctionType.BundleMonoVersion, AuctionType.BundleMonoVersionToScrap]));
    const isBundleMulti = computed<boolean>(() => checkAuctionType([AuctionType.BundleMultiVersion, AuctionType.BundleMultiVersionToScrap]));
    const isBundle = computed<boolean>(() => isBundleMono.value || isBundleMulti.value);

    return {
        isMonoAsset,
        isBundle,
        isBundleMono,
        isBundleMulti,
    };
}
