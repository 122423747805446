<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetImagesEntity } from '~/apiClient';

const { images } = defineProps({
    images: { type: Object as PropType<AuctionAssetImagesEntity>, required: true },
});

const firstImage = computed(() => images.cover[0]);
const secondImage = computed(() => images.cover[1]);
const onceHover = ref(false);
</script>

<template>
    <div class="grid grid-cols-1">
        <NuxtImg
            class="col-start-1 row-start-1"
            :src="firstImage"
            sizes="760 md:480"
            densities="x1"
            draggable="false"
            loading="lazy"
            @mouseover="onceHover = true"
        />
        <ClientOnly>
            <NuxtImg
                v-if="secondImage && onceHover"
                :src="secondImage"
                class="col-start-1 row-start-1 max-w-full opacity-0 transition-opacity hover:opacity-100"
                sizes="760 md:480"
                densities="x1"
                draggable="false"
                loading="lazy"
            />
        </ClientOnly>
    </div>
</template>
