<script setup lang="ts">
import type { ButtonColor, ButtonSize } from '#ui/types';

// PROPS
const { iconBgColor } = defineProps({
    label: { type: String as PropType<TypedTranslateReturn>, required: true },
    size: { type: String as PropType<ButtonSize>, default: undefined },
    color: { type: String as PropType<ButtonColor>, default: 'primary' },
    iconBgColor: { type: String as PropType<PaletteBgColors>, default: 'black' },
    icon: { type: String, default: 'i-heroicons-arrow-right' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
});

// DATA
const iconBgClass = computed<string>(() => getBgClass(iconBgColor));
</script>

<template>
    <UButton
        :size="size"
        :color="color"
        :label="label"
        trailing
        :disabled="disabled"
        :loading="loading"
        :block="block"
        :ui="{ block: 'justify-between' }"
    >
        <template v-if="block" #leading>
            <!-- Invisibile mock content with same icon size, to handle alignment in block mode -->
            <span class="flex p-1">
                <UIcon :name="icon" class="invisible" />
            </span>
        </template>
        <template #trailing>
            <span class="flex items-center justify-center rounded-full p-1" :class="iconBgClass">
                <UIcon :name="icon" class="flex-shrink-0 text-white" />
            </span>
        </template>
    </UButton>
</template>
