<script setup lang="ts">
import { type AuctionAssetBusEntity, type AuctionItemEntity, BusClass } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { isBundleMono, isBundleMulti } = useAuctionType(() => auction);

// DATA
const asset = computed<AuctionAssetBusEntity>(() => auction.mainAsset);
</script>

<template>
    <section class="flex flex-col gap-y-3">
        <h1 v-if="isBundleMulti" class="text-3xl font-medium sm:text-5xl">{{ tt('auction.multi.title', { count: auction.assets.length }) }}</h1>

        <template v-else>
            <p class="text-sm sm:text-lg">
                {{ asset.class.map((cl: BusClass) => tt(`asset.class.${cl}`)).join(', ') }}
            </p>
            <h1 class="text-3xl font-medium sm:text-5xl">
                <span v-if="isBundleMono">N.{{ auction.assets.length }}&nbsp;</span>{{ asset.brand.brandValue }} {{ asset.model.modelValue }}
            </h1>
        </template>
    </section>
</template>
