<script setup lang="ts">
import type { PropType } from 'vue';

import { AuctionState } from '~/apiClient';

type BidNoticeType = 'isAdmin' | 'sameCompany' | 'notScrapyard' | 'reserveNotReached' | 'unapprovedCompany' | 'inspectionExpired';

// PROPS
const { type } = defineProps({
    type: { type: String as PropType<BidNoticeType>, required: true },
    auctionState: { type: String as PropType<AuctionState>, default: undefined },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const localePath = useLocalePath();

// COMPUTED
const icon = computed(
    () =>
        ({
            isAdmin: 'i-mdi-alert-circle',
            sameCompany: 'i-mdi-alert-circle',
            unapprovedCompany: 'i-mdi-alert-circle',
            notScrapyard: 'i-mdi-alert-circle',
            reserveNotReached: 'i-mdi-shield-alert',
            inspectionExpired: 'i-mdi-alert',
        })[type]
);

const iconColor = computed(() => {
    if (type === 'inspectionExpired') return 'text-yellow-500';

    return type === 'reserveNotReached' ? 'text-red-600' : 'text-primary';
});
</script>

<template>
    <p class="flex items-center justify-start gap-2 text-xs lg:text-sm">
        <UIcon :name="icon" class="shrink-0 text-base" :class="iconColor" />
        <span v-if="type === 'isAdmin'" class="text-neutral-500">{{ tt('auction.auctionPanel.bidNotice.isAdmin') }}</span>

        <span v-else-if="type === 'sameCompany'" class="text-neutral-500">
            {{
                auctionState === AuctionState.Running
                    ? tt(`auction.auctionPanel.bidNotice.sameCompany.${AuctionState.Running}`)
                    : tt('auction.auctionPanel.bidNotice.sameCompany.base')
            }}
        </span>

        <span v-else-if="type === 'unapprovedCompany'" class="text-neutral-500">
            {{ tt('auction.auctionPanel.bidNotice.unapprovedCompany') }}
        </span>

        <span v-else-if="type === 'inspectionExpired'" class="text-neutral-500">
            {{ tt('auction.auctionPanel.bidNotice.inspectionExpired') }}
        </span>

        <NuxtLink v-else-if="type === 'notScrapyard'" :to="localePath({ name: 'faq', hash: '#scrapping' })" class="underline" target="_blank">
            <span class="text-neutral-500">{{ tt('auction.auctionPanel.bidNotice.notScrapyard') }}</span>
        </NuxtLink>

        <NuxtLink
            v-else-if="type === 'reserveNotReached'"
            :to="localePath({ name: 'faq', hash: '#reserve-price' })"
            class="underline"
            target="_blank"
        >
            <i18n-t scope="global" tag="span" class="text-neutral-500" keypath="auction.auctionPanel.bidNotice.reservePriceNotReached.base">
                <span class="text-red-600">{{ tt('auction.auctionPanel.bidNotice.reservePriceNotReached.notReached') }}</span>
            </i18n-t>
        </NuxtLink>
    </p>
</template>
