<script setup lang="ts">
// PROPS
const { activeAutoBid } = defineProps({
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    activeAutoBid: { type: Boolean, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { isLgOrGreater } = useResponsive();

// DATA
const label = computed(() => (activeAutoBid ? tt('auction.bidButtonActiveAutoBid') : tt('auction.bidButton')));
</script>

<template>
    <BaseIconBgButton
        v-if="isLgOrGreater"
        size="xl"
        color="fl-secondary"
        variant="solid"
        class="text-black"
        :label="label"
        icon-bg-color="fl-dark-blue"
        block
        :disabled="disabled"
        :loading="loading"
    />
    <UButton v-else size="lg" color="fl-secondary" variant="solid" class="text-black" :label="label" block :disabled="disabled" :loading="loading" />
</template>
