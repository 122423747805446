<script setup lang="ts">
import { objectEntries } from '@vueuse/core';
import lodashPickBy from 'lodash-es/pickBy';
import type { PropType } from 'vue';

import { type AuctionItemEntity, type AuctionListingItemEntity, AuctionState } from '~/apiClient';

const TIME_UNITS = ['days', 'hours', 'minutes', 'seconds'] as const;
type TimeUnitsKey = (typeof TIME_UNITS)[number];

const SECONDS_MINUTE = 60;
const SECONDS_HOUR = 60 * SECONDS_MINUTE;
const SECONDS_DAY = 24 * SECONDS_HOUR;

// COMPOSABLE
const { timeSyncNow } = useTimeSync();
const { tt } = useTypedI18n();

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionListingItemEntity | AuctionItemEntity>, required: true },
});

// COMPUTED
const isPending = computed(() => auction.state === AuctionState.Pending);

const totalSeconds = computed(() =>
    auction.startAt && auction.expiresAt
        ? Math.max(Math.round((Date.parse(isPending.value ? auction.startAt : auction.expiresAt) - timeSyncNow.value) / 1000), 0)
        : 0
);

const textColor = computed(() => {
    if (auction.state === AuctionState.Running && totalSeconds.value < SECONDS_MINUTE * 15) return 'text-yellow-500';

    return 'text-primary';
});

const unitLabels = computed(
    () => Object.fromEntries(TIME_UNITS.map(unit => [unit, tt(`auction.countdown.${unit}`)])) as Record<TimeUnitsKey, string>
);

const timeLabel = computed(() => {
    const totS = totalSeconds.value;

    const units = {
        days: totS >= SECONDS_DAY && Math.floor(totS / SECONDS_DAY),
        hours: totS >= SECONDS_HOUR && Math.floor((totS % SECONDS_DAY) / SECONDS_HOUR),
        minutes: totS >= SECONDS_MINUTE && Math.floor((totS % SECONDS_HOUR) / SECONDS_MINUTE),
        seconds: totS % SECONDS_MINUTE,
    };

    const usefulUnits = lodashPickBy(units, val => val !== false) as Partial<Record<TimeUnitsKey, number>>;

    return objectEntries(usefulUnits)
        .map(([unit, value]) => `${value}${unitLabels.value[unit]}`)
        .join(' ');
});
</script>

<template>
    <span v-if="totalSeconds > 0">
        <span>{{ tt(isPending ? 'auction.countdown.startsIn' : 'auction.countdown.endsIn') }}:&nbsp;</span>
        <span :class="textColor">{{ timeLabel }}</span>
    </span>
</template>
