<script setup lang="ts">
import type { AccordionItem } from '#ui/types';
import { isString } from 'lodash-es';
import lodashMergeWith from 'lodash-es/mergeWith';
import { twMerge } from 'tailwind-merge';
import type { PropType } from 'vue';

// PROPS
const { ui } = defineProps({
    items: {
        type: Array as PropType<AccordionItem[]>,
        default: undefined,
    },
    defaultOpen: {
        type: Boolean,
    },
    multiple: {
        type: Boolean,
    },
    ui: {
        type: Object,
        default: () => ({}),
    },
});

// DATA
const mergedUi = computed(() =>
    lodashMergeWith(
        {
            container: 'border-b-2 border-gray-300',
            item: {
                color: 'text-black dark:text-white',
            },
            default: {
                class: 'mb-3 w-full',
                truncate: true,
            },
        },
        ui,
        (objValue, srcValue) => {
            if (isString(objValue) && isString(srcValue)) {
                return twMerge(objValue, srcValue);
            }
        }
    )
);
</script>

<template>
    <UAccordion :items="items" :default-open="defaultOpen" :multiple="multiple" :padded="false" variant="ghost" color="black" :ui="mergedUi">
        <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
    </UAccordion>
</template>
